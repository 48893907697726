export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは3月より%d点上がりました。新年度から良いスタートですね！<br>\n5月の特集は、<b>❝女性の健康課題／禁煙のコツ❞</b>です。\r\n5月31日は世界禁煙デーです。<a href=\"https://go.andwell.jp/library/tag/master1\">こちら</a>では喫煙のリスクや禁煙中の方への励まし方などタバコを吸う人だけでなく、吸わない人にも見ていただきたい情報がまとめられています。この機会に改めて、喫煙について考えてみませんか？\r\n\n",
      "down": "生活習慣スコアは3月より%d点下がりました。気持ちを切り替え１つずつ取り組んでいきましょう！<br>\n5月の特集は、<b>❝女性の健康課題／禁煙のコツ❞</b>です。\r\n5月31日は世界禁煙デーです。<a href=\"https://go.andwell.jp/library/tag/master1\">こちら</a>では喫煙のリスクや禁煙中の方への励まし方などタバコを吸う人だけでなく、吸わない人にも見ていただきたい情報がまとめられています。この機会に改めて、喫煙について考えてみませんか？\r\n\n",
      "equal100": "生活習慣スコアは3月に引き続き%満点%です。年度始めの慌ただしい時期ですが、大変素晴らしいです！<br>\n5月の特集は、<b>❝女性の健康課題／禁煙のコツ❞</b>です。\r\n5月31日は世界禁煙デーです。<a href=\"https://go.andwell.jp/library/tag/master1\">こちら</a>では喫煙のリスクや禁煙中の方への励まし方などタバコを吸う人だけでなく、吸わない人にも見ていただきたい情報がまとめられています。この機会に改めて、喫煙について考えてみませんか？\r\n\n",
      "equal": "生活習慣スコアは3月と同じ点数です。分類別評価から伸ばせる場所を探してみましょう！<br>\n5月の特集は、<b>❝女性の健康課題／禁煙のコツ❞</b>です。\r\n5月31日は世界禁煙デーです。<a href=\"https://go.andwell.jp/library/tag/master1\">こちら</a>では喫煙のリスクや禁煙中の方への励まし方などタバコを吸う人だけでなく、吸わない人にも見ていただきたい情報がまとめられています。この機会に改めて、喫煙について考えてみませんか？\r\n\n"
    },
    "steps": {
      "up": "歩数スコアは3月より%d点上がりました。<br>\n外で歩くのが気持ち良い季節です。この調子で歩数を増やしていきましょう！<b>5/8からはWalkチーム対抗戦のエントリーも始まります！</b>",
      "down": "歩数スコアは3月より%d点下がりました。<br>\n外で歩くのが気持ち良い季節です。気分転換にお散歩して歩数を増やしていきましょう！<b>5/8からはWalkチーム対抗戦のエントリーも始まります！</b>",
      "equal": "歩数スコアは3月と同じ点数です。<br>\n外で歩くのが気持ち良い季節です。気分転換にお散歩して歩数を増やしていきましょう！<b>5/8からはWalkチーム対抗戦のエントリーも始まります！</b>"
    },
    "exercise": {
      "up": "運動のスコアは3月より%d点上がりました。良い傾向です！<br>\n<a href=\"https://go.andwell.jp/library/89fbf25e-80d6-11ed-9ab1-0af363eb5062\">この動画</a>を見て、仕事中も定期的に体を動かす習慣をつくりましょう。\n\n",
      "down": "運動のスコアは3月より%d点下がりました。<br>\n<a href=\"https://go.andwell.jp/library/89fbf25e-80d6-11ed-9ab1-0af363eb5062\">この動画</a>を見て、仕事中も定期的に体を動かし少しずつ運動習慣を増やしていきましょう。\n\n",
      "equal": "運動のスコアは3月と同じ点数です。<br>\n<a href=\"https://go.andwell.jp/library/89fbf25e-80d6-11ed-9ab1-0af363eb5062\">この動画</a>を見て、仕事中も定期的に体を動かす習慣をつくりましょう。\n\n"
    },
    "meal": {
      "up": "食事のスコアは3月より%d点上がりました。<br>\n食べる順番を意識していますか？食事を野菜から食べ始める<b>❝ベジファースト❞</b>は脂肪をためづらく、痩せやすい体づくりをサポートしてくれます。\n\n",
      "down": "食事のスコアは3月より%d点下がりました。<br>\n食べる順番を意識していますか？食事を野菜から食べ始める<b>❝ベジファースト❞</b>は脂肪をためづらく、痩せやすい体づくりをサポートしてくれます。\n\n",
      "equal": "食事のスコアは3月と同じ点数です。<br>\n食べる順番を意識していますか？食事を野菜から食べ始める<b>❝ベジファースト❞</b>は脂肪をためづらく、痩せやすい体づくりをサポートしてくれます。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは3月より%d点上がりました。<br>\n年度初めで飲み会も増えがちな時期ですが素晴らしいです！<a href=\"https://go.andwell.jp/library/256a851b-f166-11ee-95cc-0a74c02d2ee3\">こちらのコラム</a>では、自分に合ったお酒の飲み方を紹介しています。\n\n",
      "down": "飲酒のスコアは3月より%d点下がりました。<br>\n3月よりも飲み過ぎてしまった日が多かったようです。<a href=\"https://go.andwell.jp/library/256a851b-f166-11ee-95cc-0a74c02d2ee3\">こちらのコラム</a>を読んで、自分に合ったお酒の飲み方を考えてみませんか？\n\n",
      "equal": "飲酒のスコアは3月と同じ点数です。<br>\n<a href=\"https://go.andwell.jp/library/256a851b-f166-11ee-95cc-0a74c02d2ee3\">こちらのコラム</a>を読んで、自分に合ったお酒の飲み方を考えてみませんか？\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは3月より%d点上がりました。<br>\n寝る直前までスマートフォンを使っていると、ブルーライトの影響で睡眠の質が下がってしまいます。寝る2時間程前からは控えるのがおすすめです。\n\n",
      "down": "睡眠のスコアは3月より%d点下がりました。<br>\n寝る直前までスマートフォンを使っていると、ブルーライトの影響で睡眠の質が下がってしまいます。寝る2時間程前からは控えるのがおすすめです。\n\n",
      "equal": "睡眠のスコアは3月と同じ点数です。<br>\n寝る直前までスマートフォンを使っていると、ブルーライトの影響で睡眠の質が下がってしまいます。寝る2時間程前からは控えるのがおすすめです。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは3月より%d点上がりました。<br>\n5月は新年度の疲れから体調を崩しやすい時期です。目覚めたら朝日を浴びて、良い1日のスタートを切りましょう。\n\n",
      "down": "ストレスのスコアは3月より%d点下がりました。<br>\n5月は新年度の疲れから体調を崩しやすい時期です。目覚めたら朝日を浴びて、良い1日のスタートを切りましょう。\n\n",
      "equal": "ストレスのスコアは3月と同じ点数です。<br>\n5月は新年度の疲れから体調を崩しやすい時期です。目覚めたら朝日を浴びて、良い1日のスタートを切りましょう。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up by %d points since March.  That's a good start to the new school year!<br>\nMay's feature article is <b>\"Women's Health Issues/Tips for Quitting Smoking.\"</b>\r\nMay 31 is World No Smoking Day. <a href=\"https://go.andwell.jp/library/tag/master1\">Here</a> you will find a collection of information not only for smokers but also for those who do not smoke, including the risks of smoking and how to encourage those who are quitting. Why not take this opportunity to think about smoking again?\n\n",
      "down": "Your lifestyle score has gone down by %d points since March. Let's change our minds and tackle one thing at a time!<br>\nMay's feature article is <b>\"Women's Health Issues/Tips for Quitting Smoking.\"</b>\r\nMay 31 is World No Smoking Day. <a href=\"https://go.andwell.jp/library/tag/master1\">Here</a> you will find a collection of information not only for smokers but also for those who do not smoke, including the risks of smoking and how to encourage those who are quitting. Why not take this opportunity to think about smoking again?\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in March. This is a very impressive, although it is a hectic time at the beginning of the school year!<br>\nMay's feature article is <b>\"Women's Health Issues/Tips for Quitting Smoking.\"</b>\r\nMay 31 is World No Smoking Day. <a href=\"https://go.andwell.jp/library/tag/master1\">Here</a> you will find a collection of information not only for smokers but also for those who do not smoke, including the risks of smoking and how to encourage those who are quitting. Why not take this opportunity to think about smoking again?\n\n",
      "equal": "Your lifestyle score is the same as in March. Let's look for places where we can increase it from the classification rating!<br>\nMay's feature article is <b>\"Women's Health Issues/Tips for Quitting Smoking.\"</b>\r\nMay 31 is World No Smoking Day. <a href=\"https://go.andwell.jp/library/tag/master1\">Here</a> you will find a collection of information not only for smokers but also for those who do not smoke, including the risks of smoking and how to encourage those who are quitting. Why not take this opportunity to think about smoking again?\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since March.<br>\nIt is the season when it feels good to walk outside. Let's keep up the pace and increase the number of steps we take! <b>Entries for the Walk Team Competition will begin on May 8!</b>",
      "down": "Your steps score has gone down %d points since March. <br>\nIt is the season when it feels good to walk outside. Let's take a walk for a change and increase the number of steps you take! <b>Entries for the Walk team competition will begin on May 8!</b>",
      "equal": "Your steps score is the same as March. <br>\nIt is the season when it feels good to walk outside. Let's take a walk for a change and increase the number of steps you take! <b>Entries for the Walk team competition will begin on May 8!</b>"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since March.<br>\nWatch <a href=\"https://go.andwell.jp/library/89fbf25e-80d6-11ed-9ab1-0af363eb5062\">this video</a> to create a habit of regular physical activity while at work.\n\n",
      "down": "Your exercise score has gone down %d points since March.<br>\nWatch <a href=\"https://go.andwell.jp/library/89fbf25e-80d6-11ed-9ab1-0af363eb5062\">this video</a> and gradually increase your exercise routine by moving your body regularly while at work.\n\n",
      "equal": "Your exercise score is the same as March.<br>\nWatch <a href=\"https://go.andwell.jp/library/89fbf25e-80d6-11ed-9ab1-0af363eb5062\">this video</a> to create a habit of regular physical activity while at work.\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since March.<br>\nAre you conscious of the order in which you eat? <b>Eating vegetables first</b> will help you lose weight and prevent the accumulation of fat.\n\n",
      "down": "Your food score has gone down %d points since March.<br>\nAre you conscious of the order in which you eat? <b>Eating vegetables first</b> will help you lose weight and prevent the accumulation of fat.\n\n",
      "equal": "Your food score is the same as March.<br>\nAre you conscious of the order in which you eat? <b>Eating vegetables first</b> will help you lose weight and prevent the accumulation of fat.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since March.<br>\nIt's the beginning of the school year and it's a wonderful time of year when drinking tends to increase! <a href=\"https://go.andwell.jp/library/256a851b-f166-11ee-95cc-0a74c02d2ee3\">here</a> is a column on how to drink the right kind of alcohol for you.\n\n",
      "down": "Your alcohol score has gone down %d points since March.<br>\nIt seems that more days were spent drinking than in March. Read this <a href=\"https://go.andwell.jp/library/256a851b-f166-11ee-95cc-0a74c02d2ee3\">column</a> to find out how to drink the right amount of alcohol for you.\n\n",
      "equal": "Your alcohol score is the same as March.<br>\nRead this <a href=\"https://go.andwell.jp/library/256a851b-f166-11ee-95cc-0a74c02d2ee3\">colum</a> to find out how to drink the right kind of alcohol for you.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since March.<br>\nIf you use your smartphone close to bedtime, the blue light will reduce the quality of your sleep. It is recommended to refrain from using it about 2 hours before bed.\n\n",
      "down": "Your sleep score has gone down %d points since March.<br>\nIf you use your smartphone close to bedtime, the blue light will reduce the quality of your sleep. It is recommended to refrain from using it about 2 hours before bed.\n\n",
      "equal": "Your sleep score is the same as March.<br>\nIf you use your smartphone close to bedtime, the blue light will reduce the quality of your sleep. It is recommended to refrain from using it about 2 hours before bed.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since March.<br>\nMay is a time when it is easy to get sick from the fatigue of the new year. Wake up and soak up the morning sun to get a good start to the day.\n\n",
      "down": "Your stress score has gone down %d points since March.<br>\nMay is a time when it is easy to get sick from the fatigue of the new year. Wake up and soak up the morning sun to get a good start to the day.\n\n",
      "equal": "Your stress score is the same as March.<br>\nMay is a time when it is easy to get sick from the fatigue of the new year. Wake up and soak up the morning sun to get a good start to the day.\n\n"
    }
  }
}